import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MasterLayout from '../layouts/MasterLayout';

function ProtectRouteMaster() {
//   const { token: Usertoken } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.user);
  return currentUser.role === '3' ? <MasterLayout /> : <Navigate to="/Login" />;
}

export default ProtectRouteMaster