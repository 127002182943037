import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  // creditAmount
  creditAmountSucess: false,

  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

// export const getBankListAdmin = createEncryptedAsyncThunk(
//   'manageBankSlice/getBankListAdmin',
//   (params) => `bank/GetBankDetailsById?userID=${params.currentUser.userId}`,
//   {
//     method: 'GET',
//   }
// );
export const getBankListAdmin = createEncryptedAsyncThunk(
  'manageBankSlice/getBankListAdmin',
  'bank/GetBankDetailsById',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bankuserID: params.currentUser.userId,
    }),
  },
);
export const deleteAdminBank = createEncryptedAsyncThunk(
  'manageBankSlice/deleteAdminBank',
  'bank/UpdateBankDetails',
  {
    prepareBody: (params) => ({
      id: params.data.id,
      bankId: params.data.bankId,
      // userId: params.data.userId,
      userID: params.currentUser.userGuid,
      beneficiaryName: params.data.beneficiaryName,
      branchName: params.data.branchName,
      accountNumber: params.data.accountNumber,
      ifsc: params.data.ifsc,
      status: '2',
      updated_By: params.currentUser.userId,
    }),
  },
);
export const addSelfBankAdmin = createEncryptedAsyncThunk(
  'manageBankSlice/addSelfBankAdmin',
  'bank/AddBankDetails',
  {
    prepareBody: (params) => ({
      bankId: params.bankSelect.value,
      accountNumber: params.accountNumber,
      ifsc: params.ifsc,
      userID: params.currentUser.userGuid,
      isActive: true,
      beneficiaryName: params.beniName,
      branchName: params.branchName,
      created_By: 0,
    }),
  },
);

export const UpdateSelfBankAdmin = createEncryptedAsyncThunk(
  'manageBankSlice/UpdateSelfBankAdmin',
  'bank/UpdateBankDetails',
  {
    prepareBody: (params) => ({
      id: params.bankId,
      bankId: params.bankSelect.value,
      userID: params.currentUser.userGuid,
      beneficiaryName: params.beniName,
      branchName: params.branchName,
      accountNumber: params.accountNumber,
      ifsc: params.ifsc,
      status: '1',
      updated_By: params.currentUser.userId,
    }),
  },
);

export const getMasterBankDataList = createEncryptedAsyncThunk(
  'manageBankSlice/getMasterBankDataList',
  'bank/GetBankList',
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      bankId: params.bankId || 0
    }),
  },
);

export const AddMasterBankData = createEncryptedAsyncThunk(
  'manageBankSlice/AddMasterBankData',
  'Service/AddMasterBank',
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      bankName: params.bankName,
      ifsc: params.ifsc,
      branch: params.branch,
      address: params.address,
      apiId: 0
    }),
  },
);

export const UpdateMasterBankData = createEncryptedAsyncThunk(
  'manageBankSlice/UpdateMasterBankData',
  'Service/UpdateBankMaster',
  {
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      bankid: params.bankid,
      ifsc: params.ifsc,
      branchName: params.branch,
      address: params.address,
      isActive: params.isActive || false,
      apiId: 0
    }),
  },
);

// export const addMasterBankAdmin = createAsyncThunk(
//   'addSelfBankAdmin/addSelfBankAdmin',
//   async (params) => {
//     const rowData = {
//       id: 0,
//       bankId: `${params.bankSelect.value}`,
//       accountNumber: params.accountNumber,
//       ifsc: params.ifsc,
//       userID: params.currentUser.userGuid,
//       isActive: 0,
//       beneficiaryName: params.beniName,
//       branchName: params.branchName,
//       isShowForRetailor: 0,
//     };
//     const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}Modules/AddBank`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         'PT': process.env.REACT_APP_API_KEY,
//         Authorization: `Bearer ${params.Usertoken}`,
//       },
//       body: Encrypt(JSON.stringify(rowData)),
//     });
//     const data = await response.text();
//     const res = JSON.parse(JSON.parse(Decrypt(data)));
//     return res;
//   },
// );

const manageBankSlice = createSlice({
  name: 'manageBankSlice',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    credDeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    credDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    credDeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  credDeleteSuccess,
  credDeleteFailed,
  credDeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = manageBankSlice.actions;
export default manageBankSlice.reducer;
