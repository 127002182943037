import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BusinessLayout from '../layouts/BusinessLayout';

function ProtectRouteBusiness() {
//   const { token: Usertoken } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.user);
  return currentUser.role === '2'  ? <BusinessLayout /> : <Navigate to="/Login" />;
}


export default ProtectRouteBusiness