import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EmployeLayout from '../layouts/EmployeLayout';

function ProtectRouteEmploye() {
//   const { token: Usertoken } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.user);
  return currentUser.role === '7'  ? <EmployeLayout /> : <Navigate to="/Login" />;
}

export default ProtectRouteEmploye