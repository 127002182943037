import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DistributorLayout from "../layouts/DistributorLayout";

function ProtectRouteDistributor() {
  // const { token: Usertoken } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.user);
  return currentUser.role === "5" ? (
    <DistributorLayout />
  ) : (
    <Navigate to="/Login" />
  );
}
export default ProtectRouteDistributor;
