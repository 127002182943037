import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
// const events = ['load'];

const AppLogout = ({ children }) => {
  //  const navigate = useNavigate();
  let timer;
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };
  const logoutAction = () => {
    sessionStorage.clear();
    sessionStorage.clear();
    // navigate('/lockscreen', { replace: true });
    window.location.replace('/lockscreen', { replace: true });
    // window.location.pathname = '/lockscreen',
  };
  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 15 * 60 * 1000); // 10000ms = 10secs. You can change the time.
  };
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  // logs out user by clearing out auth token in sessionStorage and redirecting url to /signin page.
  return children;
};

export default AppLogout;
