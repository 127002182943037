import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logos/logo.png';

const AuthLogo = () => {
  return (
    <div className="p-0 d-flex  justify-content-center gap-2">
      <Link to="/" className="d-flex gap-2">
        <img src={logo} alt="profile"   ></img>
      </Link>
    </div>
  );
};

export default AuthLogo;
