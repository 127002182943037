import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  // creditAmount
  creditAmountSucess: false,
  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

export const manageCreditDebitReport = createEncryptedAsyncThunk(
  'CreditDebit/manageCreditDebitReport',
  'Wallet/GetCreditDebitDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.selectDistributor ? params.selectDistributor.value : 0,
      role_Id: params.currentRole,
      transaction_type: params.type,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);

export const creditSelf = createEncryptedAsyncThunk(
  'CreditDebit/creditSelf',
  'Wallet/AddWalletTopup',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      from_user_id: `${params.currentUser.userId}`,
      to_user_id: `${params.currentUser.userId}`,
      trans_type: 'credit',
      topup_mode: 1,
      amount: Number(params.creditAmount),
      narration: 'credit self',
      created_by: 0,
    }),
  },
);

export const manageCreditDebitToUser = createEncryptedAsyncThunk(
  'CreditDebit/manageCreditDebitToUser',
  'Wallet/AddWalletTopup',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      transaction_id: '',
      from_user_id: `${params.currentUser.userId}`,
      to_user_id: params.selectedUser.value,
      trans_type: params.transType.value,
      topup_mode: 1,
      amount: Number(params.body.amount),
      narration: params.body.narration,
      created_by: 0,
    }),
  },
);

export const CreditDebit = createSlice({
  name: 'CreditDebit',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    credDeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    credDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    credDeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  credDeleteSuccess,
  credDeleteFailed,
  credDeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = CreditDebit.actions;
export default CreditDebit.reducer;
